// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

$fa-font-path:"../webfonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~admin-lte/dist/css/adminlte.min.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

@import 'custom';
